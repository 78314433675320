import React, { useState } from 'react';
import { Collapse } from 'react-collapse';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const CollapseContainer = ({ title, children }) => {
  const [open, setOpen] = useState(false);

  return (
    <div className="m-2">
      <div className="flex bg-gray-300 p-4 items-center">
        <p className="flex-1 font-bold">{title}</p>
        <button
          className={
            'bg-transparent border-none pb-0 outline-none cursor-pointer'
          }
          onClick={() => setOpen(!open)}
        >
          <FontAwesomeIcon
            icon={'angle-double-up'}
            size={'2x'}
            rotation={180}
          />
        </button>
      </div>
      <Collapse
        isOpened={open}
        theme={{
          collapse: 'ReactCollapse--collapse',
          content: 'ReactCollapse--content',
        }}
      >
        <div className="bg-gray-200 p-4 text-left">
          <p>{children}</p>
        </div>
      </Collapse>
    </div>
  );
};

export default CollapseContainer;
