import React from 'react';
import { graphql } from 'gatsby';
import { useIntl } from 'gatsby-plugin-intl';
import PropTypes from 'prop-types';
import ContainerZoomImg from 'components/ContainerZoomImg';
import Title from 'components/title/title';
import Layout from 'components/layout/layout';
import AboutSection from 'components/sections/aboutSection';
import Section from 'components/sections/section';
import CollapseContainer from 'components/collapse';

const About = ({ data }) => {
  const lodges = data.aboutJson.lodges;
  const thumbails = data.allFile.nodes;
  const tips = data.aboutJson.tips;
  const intl = useIntl();

  return (
    <Layout
      pageTitle={'Les gites'}
      description={
        'Informations sur les gites proposés (Photos, chambres, pièces, tarifs...)'
      }
    >
      <div className="text-center border-solid  mr-auto ml-auto w-full xl:w-3/5 pt-32">
        {lodges.map(({ title, bedrooms, rooms, informations, prices }, i) => (
          <AboutSection key={title}>
            <Title as="h2" size="large" id={title}>
              {intl.formatMessage({ id: title })}
            </Title>
            <div className="pt-20 border-2 mr-auto ml-auto w-full xl:w-1/2">
              <div style={{ maxHeight: '200px', overflowY: 'auto' }}>
                {thumbails.map(
                  ({
                    relativeDirectory,
                    relativePath,
                    childImageSharp,
                    name,
                  }) => {
                    return relativeDirectory.includes(title) ? (
                      <ContainerZoomImg
                        key={name}
                        img={childImageSharp.fixed}
                        title={name}
                        relativePath={relativePath}
                        thumbailClass={'m-1'}
                        realSizeClass={'gallery-real-size'}
                      />
                    ) : null;
                  }
                )}
              </div>
            </div>
            <div className="mt-16">
              <Title as="h3">Chambres:</Title>
              <p className="p-2 ml-3 leading-8 mb-8 mt-1">
                {bedrooms.map(bedroom => (
                  <span key={bedroom}>
                    {intl.formatMessage({ id: bedroom })}
                    <br />
                  </span>
                ))}
              </p>
              <Title as="h3">Piéces:</Title>
              <p className="p-2 ml-3 leading-8 mb-8 mt-1">
                {rooms.map(room => (
                  <span key={room}>
                    {intl.formatMessage({ id: room })}
                    <br />
                  </span>
                ))}
              </p>
              <Title as="h3">Informations:</Title>
              <p className="p-2 ml-3 leading-8 mb-8 mt-1">
                {informations.map(information => (
                  <span key={information}>
                    {intl.formatMessage({ id: information })}
                    <br />
                  </span>
                ))}
              </p>
              <table className="w-full md:w-3/4 lg:w-1/2 xl:w-1/2 flex flex-row flex-no-wrap overflow-hidden sm:shadow-lg mt-1">
                <thead className="text-white">
                  <tr className="bg-gray-900 text-white flex flex-col flex-no wrap sm:table-row mb-2 sm:mb-0">
                    <th className="p-3">
                      {intl.formatMessage({ id: 'lodge-season' })}
                    </th>
                    <th className="p-3">
                      {intl.formatMessage({ id: 'lodge-off-season' })}
                    </th>
                    <th className="p-3">
                      {intl.formatMessage({ id: 'lodge-weekend' })}
                    </th>
                  </tr>
                </thead>
                <tbody className="flex-1 sm:flex-none">
                  <tr className="flex flex-col flex-no wrap sm:table-row mb-2 sm:mb-0 bg-gray-200 text-gray-700">
                    <td className="border-grey-light border p-3">
                      {prices.season}
                    </td>
                    <td className="border-grey-light border p-3">
                      {prices.offSeason}
                    </td>
                    <td className="border-grey-light border p-3">
                      {prices.weekend}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            {i + 1 < lodges.length ? (
              <hr className="my-32" />
            ) : (
              <div className="pb-32" />
            )}
          </AboutSection>
        ))}
        <Section className="bg-gray-500">
          <Title as="h2" size="large">
            {intl.formatMessage({ id: 'tips-title' })}
          </Title>
          <div className="pt-10">
            {tips.map(({ title, content }) => (
              <CollapseContainer
                key={title}
                title={intl.formatMessage({ id: title })}
              >
                {content.map(text => (
                  <p key={text}>{intl.formatMessage({ id: text })}</p>
                ))}
              </CollapseContainer>
            ))}
          </div>
        </Section>
      </div>
    </Layout>
  );
};

export default About;

About.propTypes = {
  data: PropTypes.object.isRequired,
};

export const query = graphql`
  query AboutQuery {
    aboutJson {
      tips {
        title
        content
      }
      lodges {
        title
        bedrooms
        rooms
        informations
        prices {
          season
          offSeason
          weekend
        }
      }
    }
    allFile(
      filter: { internal: {}, relativeDirectory: { regex: "images/lodges/" } }
    ) {
      nodes {
        relativeDirectory
        relativePath
        name
        childImageSharp {
          fixed(width: 100, height: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  }
`;
